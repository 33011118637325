import React, { useState,useEffect,useRef } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AnalysisIcon from '@mui/icons-material/Assessment'; // 假设这是分析题目的图标
import 'katex/dist/katex.min.css';
import katex from 'katex';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { blue, orange } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../SnackbarProvider';
import { requestUrl } from '../../requestUrl';
import LoadingData from '../dataloading';
import CustomButton from '../../components/CustomButton';
import { getColorDark,getColorLight ,getTheme} from '../../ColorManager';
import DeleteIcon from '@mui/icons-material/Delete';
import { DragHandle } from '@mui/icons-material';
import { Box, Divider, Toolbar, Typography, IconButton,TextField,Button,Grid,Tabs, Tab,Chip } from '@mui/material';
import { useCodeMirror } from '@uiw/react-codemirror';
import { cpp } from '@codemirror/lang-cpp'; // 导入 C++ 语言扩展

import './ProblemDetail.css';
import { EditorView, basicSetup } from 'codemirror';
import { EditorState } from '@codemirror/state';
import { oneDark } from '@codemirror/theme-one-dark';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'; // 引入 rehype-raw 插件
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import '../ChatWindow/MessageItem.css';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
// 外面的壳子
//detailtype 为p,表示传入的时pid，problem_list的id，根据这个id获取信息，学生点击了题目
//detailtype为s，表示传入的是spid，problem_submit_code的id，根据这个spid获取信息
const ProblemDetail = ({row,onClose,spid,pid,detailtype}) => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText,setLoadingText] = useState('loading');
  const [userid,setUserid] = useState(0);
  const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组

  const [leftWidth, setLeftWidth] = useState(35);
  const [inputValue, setInputValue] = useState('');

  const editorRef = useRef(null);

  const [editorView, setEditorView] = useState(null);
  const [problemData,setProblemData] = useState({});


  const [tags,setTags] = useState([]);



  const get_color_by_name = (name) => {

    const item = colorManager.find(item => item.container === name);
    return item.value;
  }

  useEffect(() => {
    if (editorRef.current) {
      const startState = EditorState.create({
        doc: problemData.sourcecode,
        extensions: [
          basicSetup,
          cpp(),
          oneDark,
          EditorView.theme({
            "&": {
              height: "100%", // 使用父div的100%高度
            },
            ".cm-scroller": {
              overflow: "auto",
              maxHeight: "100%", // 限制scroller的最大高度
            },
          })
        ]
      });

      const view = new EditorView({
        state: startState,
        parent: editorRef.current
      });

      setEditorView(view);

      return () => {
        view.destroy();
      };
    }
  }, [problemData]);




  useEffect(() => {
    console.log("dtype::" + detailtype);
    if(detailtype === "s"){
      const fetchData = async () => {
          try {
              setIsLoading(true);
              setLoadingText("loading");
              const dataToSend = {
                  id:spid,
              };
              const data = await requestUrl(dataToSend, "get_student_problem_detail_by_spid",t,showSnackbar);
              if(data.status === "success"){
                console.log(data.data);
                const data1 = data.data;
                setProblemData(data1);
                setTags(data.tags);
                setBuildResult(data.resbuild);
              }
              
              
            } catch (error) {
                console.error("获取数据时出错:", error);
            } finally {
              setIsLoading(false);
            }
      };

      fetchData();
    }

    if(detailtype === "p"){
      const fetchData1 = async () => {
        try {
            setIsLoading(true);
            setLoadingText("loading");
            const dataToSend = {
                id:spid,
            };
            const data = await requestUrl(dataToSend, "get_student_problem_detail_by_pid",t,showSnackbar);
            if(data.status === "success"){
              console.log(data.data);
              const data1 = data.data;
              setProblemData(data1);

            }
            
            
          } catch (error) {
              console.error("获取数据时出错:", error);
          } finally {
            setIsLoading(false);
          }
    };

    fetchData1();
    }
  }, [detailtype]);



 // 获取编辑器内容
 const getCodeContent = () => {
  if (editorView) {
    const content = editorView.state.doc.toString();
    console.log("Editor content:", content);
    return content;
  }
};




  const handleDrag = (e) => {
    const newWidth = (e.clientX / window.innerWidth) * 100;
    setLeftWidth(newWidth);
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2); // 获取年份的后两位
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要+1
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');

    return `${year}-${month}-${day}:${hour}`;
  };



  const inputStyles = {
    color:get_color_by_name('mui-textfield-text-color'),
    fontFamily: 'siyuan',
  };



  const handleClose = () => {

    onClose();
  }

  
  const handleInputChange = (e) => {
   
    setInputValue(e.target.value);
  };


  const memoryUsage = '128k'; // 内存使用量的变量
  const timeUsage = '0.03S';  // 时间使用量的变量




    // 插入文本到当前光标位置
    const insertTextAtCursor = (text) => {
      if (editorView) {
        const transaction = editorView.state.update({
          changes: {
            from: editorView.state.selection.main.from, // 当前光标位置的起点
            to: editorView.state.selection.main.to, // 当前光标位置的终点（支持选择插入）
            insert: text // 要插入的文本
          }
        });
        editorView.dispatch(transaction);
      }
    };


// 覆盖编辑器内容
const overwriteContent = (newContent) => {
  if (editorView) {
    const transaction = editorView.state.update({
      changes: {
        from: 0, // 起始位置
        to: editorView.state.doc.length, // 结束位置（覆盖整个内容）
        insert: newContent // 新的内容
      }
    });
    editorView.dispatch(transaction);
  }
};


const aaaaa = "$x^2$是什么意思";


const [valueTabIndex, setValueTabIndex] = useState(0);
const handleChangeTabIndex = (event, newValue) => {
  setValueTabIndex(newValue);
};





const [buildResult,setBuildResult] = useState([]);



const handleSaveCode = async () => {
  console.log("songsss");
  buildSaveCode(0);
}



const handleBuildCode = async () => {
  
  buildSaveCode(1);

}


const buildSaveCode = async (isBuild) =>{

  const code = getCodeContent();
  //const p = problemData.input_p || '';
  const p = problemData.input_model1;
  console.log(p);
  try{
    setIsLoading(true);
    setLoadingText("loading");
    const dataToSend = {
      spid: spid,
      code_type:'cpp',
      code_p:p,
      code:code,
      memory_limit:problemData.memory_limit || 0,
      cpu_time_limit:problemData.time_limit || '',
      isBuild:isBuild,
    };

    console.log(JSON.stringify(dataToSend));
    const data = await requestUrl(dataToSend, "build_code",t,showSnackbar);
    if(data.status === "success"){
      console.log(data.data);
      setBuildResult(data.data);

    }
  } 
  catch (error) {
      console.error("获取数据时出错:", error);
  } 
  finally {
    setIsLoading(false);
  }
}

const handleGetBuildDetail = async () =>{


}

    return (
      <div style={{ height: '100%', width:'100%', display: 'flex', flexDirection: 'column' ,backgroundColor:get_color_by_name('pop-window-bg'),}}>
      
        <Box sx={{ height: '100vh' }}>
        {/* 上部工具栏 */}
         
          {/* 下部分 */}
          <Box sx={{ display: 'flex', height: 'calc(100% - 64px)' }}>
            {/* 左侧部分 */}
            <Box
                sx={{
                    overflowY: 'auto',
                    height:'800px',
                    width: `${leftWidth}%`,
                    borderRadius: 2,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor:get_color_by_name('pop-window-bg'),
                    border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
                }}
                >

                <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                    题目标题
                </Typography>
                <Typography variant="body1" gutterBottom>
                <div className="markdown-r">
                  <ReactMarkdown
                    children={problemData.question_title}
                    remarkPlugins={[remarkMath]}
                    rehypePlugins={[rehypeRaw, rehypeKatex]}
                  />
                </div>
                </Typography>


                <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                    题目描述
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <div className="markdown-r">
                    <ReactMarkdown
                      children={problemData.question_des}
                      remarkPlugins={[remarkMath]}
                      rehypePlugins={[rehypeRaw, rehypeKatex]}
                    />
                  </div>
                </Typography>
                <Divider sx={{ width: '100%', marginBottom: '16px' }} />

                <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                    输入格式
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <div className="markdown-r">
                    <ReactMarkdown
                      children={problemData.input_format}
                      remarkPlugins={[remarkMath]}
                      rehypePlugins={[rehypeRaw, rehypeKatex]}
                    />
                  </div>
                </Typography>
                <Divider sx={{ width: '100%', marginBottom: '16px' }} />


                <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                    标签
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <div>
                    {tags.map((tag) => (
                      <Chip
                        key={tag.id}
                        label={tag.tag_name_zh}
                        style={{
                          backgroundColor: getRandomColor(), // 随机背景颜色
                          color: 'white',                    // 字体颜色为白色
                          margin: '0 10px 10px 0'            // 标签间隔
                        }}
                      />
                    ))}
                  </div>
                </Typography>




                <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                    提交日期
                </Typography>
                <Typography variant="body1" gutterBottom>
                <TextField
    InputLabelProps={{
    style: {
        color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
    },
    }}
    
    sx={{
    '& .MuiInputBase-root': {
        backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
        borderColor: get_color_by_name('mui-textfield-bg'),
        },
        '&.Mui-focused fieldset': {
        borderColor: 'primary.main',
        },
        '& fieldset': {
        borderColor: get_color_by_name('mui-textfield-bg'),
        },
    },
    }}
    fullWidth
    multiline
    rows={1}
    value={problemData.commit_time}
    autoFocus
    InputProps={{
        sx: inputStyles,
        }}
        />
                </Typography>

                <Grid container spacing={2}>
                <Grid item xs={6}>

<Typography variant="subtitle1" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
    内存限制
</Typography>
    <TextField
    InputLabelProps={{
    style: {
        color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
    },
    }}
    
    sx={{
    '& .MuiInputBase-root': {
        backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
        borderColor: get_color_by_name('mui-textfield-bg'),
        },
        '&.Mui-focused fieldset': {
        borderColor: 'primary.main',
        },
        '& fieldset': {
        borderColor: get_color_by_name('mui-textfield-bg'),
        },
    },
    }}
    fullWidth
    multiline
    rows={1}
    value={problemData.memory_limit}
    autoFocus
    InputProps={{
        sx: inputStyles,
        }}
        />
    </Grid>

  <Grid item xs={6}>
  <Typography variant="subtitle1" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
      时间限制
  </Typography>
  <TextField
    InputLabelProps={{
    style: {
        color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
    },
    }}
    
    sx={{
    '& .MuiInputBase-root': {
        backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
        borderColor: get_color_by_name('mui-textfield-bg'),
        },
        '&.Mui-focused fieldset': {
        borderColor: 'primary.main',
        },
        '& fieldset': {
        borderColor: get_color_by_name('mui-textfield-bg'),
        },
    },
    }}
    fullWidth
    multiline

    value={problemData.time_limit}
    autoFocus
    InputProps={{
    sx: inputStyles,
    }}
      />
  </Grid>

                </Grid>


                <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                    输入输出样例
                </Typography>
                


                <Grid container spacing={2}>
        <Grid item xs={6}>

          <Typography variant="subtitle1" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
            输入 #1
          </Typography>
          <TextField
                
            InputLabelProps={{
            style: {
                color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
            },
            }}
            
            sx={{
            '& .MuiInputBase-root': {
                backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                borderColor: get_color_by_name('mui-textfield-bg'),
                },
                '&.Mui-focused fieldset': {
                borderColor: 'primary.main',
                },
                '& fieldset': {
                borderColor: get_color_by_name('mui-textfield-bg'),
                },
            },
            }}
            fullWidth
            multiline
            
            value={problemData.input_model1}
            
            autoFocus
            InputProps={{
            sx: inputStyles,
            endAdornment: (
                <Button
                variant="outlined"
                size="small"
                onClick={() => navigator.clipboard.writeText('32')}
                >
                复制
                </Button>
            ),
            readOnly: true,
            }}
                />
        </Grid>

        <Grid item xs={6}>
        <Typography variant="subtitle1" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
            输出 #1
          </Typography>
          <TextField
            InputLabelProps={{
            style: {
                color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
            },
            }}
            
            sx={{
            '& .MuiInputBase-root': {
                backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                borderColor: get_color_by_name('mui-textfield-bg'),
                },
                '&.Mui-focused fieldset': {
                borderColor: 'primary.main',
                },
                '& fieldset': {
                borderColor: get_color_by_name('mui-textfield-bg'),
                },
            },
            }}
            fullWidth
            multiline
        
            value={problemData.output_model1}
            
            autoFocus
            InputProps={{
            sx: inputStyles,
            endAdornment: (
                <Button
                variant="outlined"
                size="small"
                onClick={() => navigator.clipboard.writeText('32')}
                >
                复制
                </Button>
            ),
            readOnly: true,
            }}
              />
        </Grid>

        


        <Grid item xs={6}>

          <Typography variant="subtitle1" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
            输入 #2
          </Typography>
          <TextField
                
            InputLabelProps={{
            style: {
                color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
            },
            }}
            
            sx={{
            '& .MuiInputBase-root': {
                backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                borderColor: get_color_by_name('mui-textfield-bg'),
                },
                '&.Mui-focused fieldset': {
                borderColor: 'primary.main',
                },
                '& fieldset': {
                borderColor: get_color_by_name('mui-textfield-bg'),
                },
            },
            }}
            fullWidth
            multiline
            
            value={problemData.input_model2}
            
            autoFocus
            InputProps={{
            sx: inputStyles,
            endAdornment: (
                <Button
                variant="outlined"
                size="small"
                onClick={() => navigator.clipboard.writeText('32')}
                >
                复制
                </Button>
            ),
            readOnly: true,
            }}
                />
        </Grid>

        <Grid item xs={6}>
        <Typography variant="subtitle1" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
            输出 #2
          </Typography>
          <TextField
            InputLabelProps={{
            style: {
                color: get_color_by_name('mui-textfield-text-color'), // 使用主题颜色
            },
            }}
            
            sx={{
            '& .MuiInputBase-root': {
                backgroundColor: get_color_by_name('mui-textfield-bg'), // 自定义背景色
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                borderColor: get_color_by_name('mui-textfield-bg'),
                },
                '&.Mui-focused fieldset': {
                borderColor: 'primary.main',
                },
                '& fieldset': {
                borderColor: get_color_by_name('mui-textfield-bg'),
                },
            },
            }}
            fullWidth
            multiline
        
            value={problemData.output_model2}
            
            autoFocus
            InputProps={{
            sx: inputStyles,
            endAdornment: (
                <Button
                variant="outlined"
                size="small"
                onClick={() => navigator.clipboard.writeText('32')}
                >
                复制
                </Button>
            ),
            readOnly: true,
            }}
              />
        </Grid>


      </Grid>
                
                <Divider sx={{ width: '100%', marginBottom: '16px' }} />

                <Typography variant="h6" component="h2" id="modal-modal-title" sx={{ mb: 2 ,backgroundColor:get_color_by_name('div-bg'), color:get_color_by_name('headline-color')}}>
                    说明提示
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <div className="markdown-r">
                      <ReactMarkdown
                        children={problemData.data_rule}
                        remarkPlugins={[remarkMath]}
                        rehypePlugins={[rehypeRaw, rehypeKatex]}
                      />
                    </div>
                </Typography>
                
            </Box>

            {/* 拖拽分隔线 */}
            <Box
              sx={{
                width: '10px', // 调整线条宽度
                backgroundColor: get_color_by_name('div-bg'),
                cursor: 'col-resize',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onMouseDown={(e) => {
                document.addEventListener('mousemove', handleDrag);
                document.addEventListener('mouseup', () => {
                  document.removeEventListener('mousemove', handleDrag);
                });
              }}
            >
              <DragHandle />
            </Box>
            {/* 右侧部分 */}
            <Box
              sx={{
                width: `${100 - leftWidth}%`,
                display: 'flex',
                flexDirection: 'column', // 让内容垂直排列
                justifyContent:'top',
                alignItems: 'flex-start',
                height:'860px',
                backgroundColor: get_color_by_name('message-item-bg'),
              }}
            >
              





              <Box sx={{ width: '100%' }}>
      <Tabs
        value={valueTabIndex}
        onChange={handleChangeTabIndex}
        aria-label="basic tabs example"
        sx={{ 
          backgroundColor: get_color_by_name('mui-textfield-bg'), 
          color: 'white' 
        }}
        TabIndicatorProps={{
          style: {
            backgroundColor: 'white',
          },
        }}
      >
        <Tab label="代码" sx={{
            color: 'white',
            '&.Mui-selected': {
              color: 'yellow', // 被选中时的字体颜色
            },
          }} />
        <Tab label="AI提问" sx={{
            color: 'white',
            '&.Mui-selected': {
              color: 'yellow', // 被选中时的字体颜色
            },
          }} />
        <Tab label="辅导记录" sx={{
            color: 'white',
            '&.Mui-selected': {
              color: 'yellow', // 被选中时的字体颜色
            },
          }} />
      </Tabs>
      <Box sx={{ width: '100%', height: '100%', mt: 2 }}>
        {valueTabIndex === 0 && (
          <Box sx={{ p: 3, border: '1px solid', height: '100%' }}>
            <div 
                ref={editorRef} 
                style={{ 
                  width: '100%',
                  height: '550px', // 固定高度为600px
                  minHeight: '550px', // 最小高度为600px
                  maxHeight: '550px', // 最大高度为600px，确保显示滚动条
                  display: 'flex', // 使内容充满父容器
                  flexDirection: 'column',
                  overflowY: 'auto', // 超出内容时显示垂直滚动条
                  overflowX: 'auto', // 禁用水平滚动条
                  border: '1px solid #ccc', // 可选：添加边框以便更好地查看滚动条
                  marginRight:'20px'
                }}

              >
                {/* CodeMirror 编辑器将被渲染在这个 div 中 */}
              </div>
              
              <Box sx={{ width: '100%',marginTop:'10px' }}>
                
              <Grid container spacing={2} alignItems="center">
      {/* 第一列：编译信息 */}
      <Grid item xs={1.1}>
        <Typography >编译信息</Typography>
      </Grid>

      {/* 第二列：文本框 */}
      <Grid item xs={4.2}>
      <TextField
                    multiline
                    value={buildResult.message}
                    onChange={handleInputChange}
                    sx={{
                      width: '100%',
                      backgroundColor: get_color_by_name('mui-textfield-bg'),
                      // Targeting the input element for styling
                      '& .MuiInputBase-input': {
                        color: 'white', // Change text color
                      },
                      // Targeting the label for styling
                      '& .MuiInputLabel-root': {
                        color: 'white', // Change label color
                      },
                      // Targeting the outlined border for styling
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'),
                        },
                        '&:hover fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color when focused
                        },
                      },
                    }}
                  />
      </Grid>

      {/* 第三列：编译错误 */}
      <Grid item xs={1.1}>
        <Typography>编译错误</Typography>
      </Grid>

      {/* 第四列：文本框 */}
      <Grid item xs={4.2}>
      <TextField
                    multiline
                    value={buildResult.stderr}
                    onChange={handleInputChange}
                    sx={{
                      width: '100%',
                      backgroundColor: get_color_by_name('mui-textfield-bg'),
                      // Targeting the input element for styling
                      '& .MuiInputBase-input': {
                        color: 'white', // Change text color
                      },
                      // Targeting the label for styling
                      '& .MuiInputLabel-root': {
                        color: 'white', // Change label color
                      },
                      // Targeting the outlined border for styling
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'),
                        },
                        '&:hover fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color when focused
                        },
                      },
                    }}
                  />
      </Grid>

      <Grid item xs={1.4}>
      <CustomButton onClick={handleGetBuildDetail} >
                  {t('detail')}
              </CustomButton>
      </Grid>
    </Grid>
    <br/>
    
                <Grid container spacing={2} columns={4}>
                  
                  <Grid item xs={1}>

                  <TextField
                    label="输入参数"
                    multiline
                    rows={4}
                    value={buildResult.stdin}
                    onChange={handleInputChange}
                    sx={{
                      width: '100%',
                      backgroundColor: get_color_by_name('mui-textfield-bg'),
                      // Targeting the input element for styling
                      '& .MuiInputBase-input': {
                        color: 'white', // Change text color
                      },
                      // Targeting the label for styling
                      '& .MuiInputLabel-root': {
                        color: 'white', // Change label color
                      },
                      // Targeting the outlined border for styling
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'),
                        },
                        '&:hover fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color when focused
                        },
                      },
                    }}
                  />
                  </Grid>
                 
                  <Grid item xs={1}>
                  <TextField
                    label="输出参数"
                    multiline
                    rows={4}
                    value={buildResult.stdout}
                    onChange={handleInputChange}
                    sx={{
                      width: '100%',
                      backgroundColor: get_color_by_name('mui-textfield-bg'),
                      // Targeting the input element for styling
                      '& .MuiInputBase-input': {
                        color: 'white', // Change text color
                      },
                      // Targeting the label for styling
                      '& .MuiInputLabel-root': {
                        color: 'white', // Change label color
                      },
                      // Targeting the outlined border for styling
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'),
                        },
                        '&:hover fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: get_color_by_name('pop-window-border-color'), // Change border color when focused
                        },
                      },
                    }}
                  />
                  </Grid>
                  

                  <Grid item xs={1}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <Typography
                        variant="h6" // 使用更大的字体
                        sx={{
                          color: '#d32f2f', // 红色字体，更醒目
                          mb: 1, // 添加下边距
                        }}
                      >
                        内存使用：{buildResult.memory} KB
                      </Typography>
                      
                      <Typography
                        variant="h6" // 使用更大的字体
                        sx={{
                          color: '#d32f2f', // 红色字体，更醒目
                        }}
                      >
                        时间使用：{buildResult.time} S
                      </Typography>
                    </Box>
                  </Grid>



                  <Grid item xs={1}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column', // 将按钮上下排列
                        alignItems: 'flex-end', // 使按钮靠右对齐
                        marginRight: '20px', // 距离右侧边缘20px
                      }}
                    >
                     <Button
                        variant="contained"
                        sx={{
                          width:'200px',
                          backgroundColor: '#1976d2', // 蓝色背景
                          color: 'white',
                            '&:hover': {
                              backgroundColor: '#1565c0', // 悬停时的颜色
                            },
                            mb: 2, // 添加下边距，使两个按钮之间有间距
                          }}
                          onClick={handleSaveCode}
                        >
                        保存
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          width:'200px',
                          backgroundColor: '#1976d2', // 蓝色背景
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#1565c0', // 悬停时的颜色
                          },
                          mb: 2, // 添加下边距，使两个按钮之间有间距
                        }}
                        onClick={handleBuildCode}
                      >
                        编译运行
                      </Button>

                      <Button
                        variant="contained"
                        onClick={handleClose}
                        sx={{
                          width:'200px',
                          backgroundColor: '#388e3c', // 绿色背景
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#2e7d32', // 悬停时的颜色
                          },
                        }}
                      >
                        关闭
                      </Button>

                    </Box>
                  </Grid>

                </Grid>
              </Box>
          </Box>
        )}
        {valueTabIndex === 1 && (
          <Box sx={{ p: 3, border: '1px solid', height: '100%' }}>
            <Typography variant="body1" sx={{ color: 'white' }}>Content of Tab 2</Typography>
            <div style={{ height: '100%', color: 'white' }}>This is a child div in Tab 2</div>
          </Box>
        )}
        {valueTabIndex === 2 && (
          <Box sx={{ p: 3, border: '1px solid', height: '100%',gap:'10px' }}>
            <Typography variant="body1" sx={{ color: 'white' }}>Content of Tab 3</Typography>
            <div style={{ height: '100%', color: 'white' }}>This is a child div in Tab 3</div>
          </Box>
        )}
      </Box>
    </Box>



    

            </Box>

          </Box>
        </Box>

        {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}
    </div>
    )
}

export default ProblemDetail