import React, { useState,useEffect,useRef } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AnalysisIcon from '@mui/icons-material/Assessment'; // 假设这是分析题目的图标
import 'katex/dist/katex.min.css';
import katex from 'katex';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { blue, orange } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../SnackbarProvider';
import { requestUrl } from '../../requestUrl';
import CustomButton from '../../components/CustomButton';
import { getColorDark,getColorLight ,getTheme} from '../../ColorManager';
import { useCodeMirror } from '@uiw/react-codemirror';
import { cpp } from '@codemirror/lang-cpp'; // 导入 C++ 语言扩展
import { Box, Grid, List, ListItem, ListItemText, Card, CardContent, CardMedia, Typography, Button } from '@mui/material';

import { EditorView, basicSetup } from 'codemirror';
import { EditorState } from '@codemirror/state';
import { oneDark } from '@codemirror/theme-one-dark';
import LoadingData from '../dataloading';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'; // 引入 rehype-raw 插件
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import '../ChatWindow/MessageItem.css';
import { getHostNameWeb } from '../../utils/urlUtils';
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
// 外面的壳子
//detailtype 为p,表示传入的时pid，problem_list的id，根据这个id获取信息，学生点击了题目
//detailtype为s，表示传入的是spid，problem_submit_code的id，根据这个spid获取信息
const OiCoursewareComponent = () => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText,setLoadingText] = useState('loading');
  const [userid,setUserid] = useState(0);
  const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组

  const [leftWidth, setLeftWidth] = useState(35);
  const [inputValue, setInputValue] = useState('');

  const editorRef = useRef(null);

  const [editorView, setEditorView] = useState(null);
  const [problemData,setProblemData] = useState({});


  const [tags,setTags] = useState([]);

  const [className,setClassName] = useState('');
  const [listItems,setListItems] = useState([]);


  const get_color_by_name = (name) => {

    const item = colorManager.find(item => item.container === name);
    return item.value;
  }




  useEffect(() => {
   
    
      const fetchData = async () => {
          try {
              setIsLoading(true);
              setLoadingText("loading");
              const dataToSend = {
                  classname:className,
              };
              const data = await requestUrl(dataToSend, "get_oicourseware_classname",t,showSnackbar);
              if(data.status === "success"){
                console.log(data.data);
                
                setListItems(data.data);
                setCardData(data.dataall);
                
              }
              
              
            } catch (error) {
                console.error("获取数据时出错:", error);
            } finally {
              setIsLoading(false);
            }
      };

      fetchData();
    

    
  }, []);





// 右侧卡片数据模拟
const [cardData,setCardData] = useState([]);


  const handleListItemClick =  async (item) => {
    setClassName(item);
    console.log("Selected item:", item);
    try {
        setIsLoading(true);
        setLoadingText("loading");
        const dataToSend = {
            classname:item,
        };
        const data = await requestUrl(dataToSend, "get_oicourseware",t,showSnackbar);
        if(data.status === "success"){
          console.log(data.data);
          setCardData(data.data);
        }
        
        
      } catch (error) {
          console.error("获取数据时出错:", error);
      } finally {
        setIsLoading(false);
      }
  };


  const handleGetAllCourseware =  async (item) => {
  
    try {
        setIsLoading(true);
        setLoadingText("loading");
        const dataToSend = {
            classname:"",
        };
        const data = await requestUrl(dataToSend, "get_oicourseware",t,showSnackbar);
        if(data.status === "success"){
          console.log(data.data);
          setCardData(data.data);
        }
        
        
      } catch (error) {
          console.error("获取数据时出错:", error);
      } finally {
        setIsLoading(false);
      }
  };
  


return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
      <Grid container>
        {/* 左侧部分 */}
        <Grid item xs={2} sx={{ borderRight: '1px solid #ddd', padding: 2}}>
        <CustomButton onClick={handleGetAllCourseware} disabled={isLoading} width='100%'>
        {t('display all')}
        </CustomButton>
          <List>
            {listItems.map((item, index) => (
              <ListItem button key={index} onClick={() => handleListItemClick(item)}>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* 右侧部分 */}
        <Grid item xs={10} sx={{ padding: 2 }}>
          
          <Grid container spacing={2}>
            {cardData.map((card) => (
              <Grid 
              item 
              xs={12}       // 在超小屏幕下每行显示1个
              sm={6}        // 在小屏幕下每行显示2个
              md={4}        // 在中等屏幕下每行显示3个
              lg={2}        // 在大屏幕下每行显示6个
              key={card.id}
            >
                <Card>
                <CardMedia
  component="img"
  image={`${getHostNameWeb()}/document/coursewareicon/${card.imageUrl}`}
  alt={card.title}
  sx={{
    width: '80px',          // 固定宽度
    height: '80px',         // 固定高度
    objectFit: 'contain',   // 保持图片比例显示整个内容
    margin: '0 auto',       // 水平居中
    display: 'block'        // 使图片为块级元素
  }}
/>
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" component="div"
                    sx={{
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      {card.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginY: 1,
    }}>
                      {card.description}
                    </Typography>
                    <Button
                      size="large"
                      color="primary"
                      href={card.link}
                      target="_blank"
                      sx={{ display: 'flex', justifyContent: 'center', marginX: 'auto' }}
                    >
                      打开课件
                    </Button>
                  </CardContent>

                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      {isLoading && (
                <LoadingData text={t(loadingText)}/>
            )}

    </Box>
  );
};

export default OiCoursewareComponent;