import React, { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AnalysisIcon from '@mui/icons-material/Assessment'; // 假设这是分析题目的图标
import 'katex/dist/katex.min.css';
import katex from 'katex';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { blue, orange } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../SnackbarProvider';
import { requestUrl } from '../../requestUrl';
import LoadingData from '../dataloading';
import CustomButton from '../../components/CustomButton';
import { getColorDark,getColorLight ,getTheme} from '../../ColorManager';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination,Modal,Checkbox ,TextField,Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ProblemDetail from './ProblemDetail';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import CommonComboboxOne from '../../components/CommonComboboxOne.jsx';
import CommonCombobox from '../../components/CommonCombobox.jsx';
import InfoIcon from '@mui/icons-material/Info';


// 随机生成颜色的函数
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};



const MyOiModule = ({commonChatSessionClassName,commonChatSessionClassId,onClose,onConfirm,onHiddenClassDetail}) => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText,setLoadingText] = useState('loading');
  const [userid,setUserid] = useState(0);
  const [colorManager, setColorManager] = useState(getColorDark()); // 初始化为空数组
  const [tableData, setTableData] = useState([]);
  const [totalPagesSession, setTotalPagesSession] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const [selectedRowId, setSelectedRowId] = useState(null);//problem_list的id
  const [selectedRowMyHomeworkId, setSelectedRowMyHomeworkId] = useState(null);//problem_code_submit的id
  const [problemDetailType, setProblemDetailType] = useState(null);

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showProblemDetail,setShowProblemDetail] = useState(false);
  const [tagColors, setTagColors] = useState({});
 // 状态：选择的值
 const [selectedValue, setSelectedValue] = useState('CSP-J');


 const [tableDataMyHomework, setTableDataMyHomework] = useState([]);




  const tags = [
    'Array',
    'Binary Tree',
    'Segment Tree',
    'Bubble Sort',
    'Linked List',
    'Queue',
    'Stack',
    'Heap',
    'Graph',
    'HashMap',
    'DFS',
    'BFS',
    'Dynamic Programming',
    'Binary Search',
    'Quick Sort',
    'Merge Sort',
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2); // 获取年份的后两位
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要+1
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');

    return `${year}-${month}-${day}:${hour}`;
  };


  
  const get_color_by_name = (name) => {

    const item = colorManager.find(item => item.container === name);
    return item.value;
  };



  const loaddataPlist = async (page_num) => {
    try {
        setIsLoading(true);
        setLoadingText("loading");
        const dataToSend = {
          page:totalPagesSession,
          per_page:20,
          question_title:"",
          question_code:"",
          difficulty_level:"",
        };
        const data = await requestUrl(dataToSend, "get_problem_list",t,showSnackbar);
        if(data.status === "success"){
          console.log(data.data);
          setTotalPagesSession(data.total_pages);

          setTableData(data.data);
        }
        
        
      } catch (error) {
          console.error("获取数据时出错:", error);
      } finally {
        setIsLoading(false);
      }
};



  useEffect(() => {
   
    loaddataPlist(1); 
  }, []);



  const fetchHomeWork = async () => {
    try {
        setIsLoading(true);
        setLoadingText("loading");
        const dataToSend = {
          id:1,
        };
        const data = await requestUrl(dataToSend, "get_my_homework",t,showSnackbar);
        if(data.status === "success"){
          console.log(data.data);
          

          setTableDataMyHomework(data.data);
        }
        
        
      } catch (error) {
          console.error("获取数据时出错:", error);
      } finally {
        setIsLoading(false);
      }
};


  useEffect(() => {
    

    fetchHomeWork(); 
  }, []);



  useEffect(() => {
    // 初始化时生成每个标签的随机颜色
    const colors = {};
    tags.forEach(tag => {
      colors[tag] = getRandomColor();
    });
    setTagColors(colors);
  }, []); // 只在组件加载时执行一次

  

  const handleChangePage = async (event, newPage) => {
    setCurrentPage(newPage);
    loaddataPlist(newPage);
    //loadDataCommonChatSessionList(newPage);
  };


  const handleClickRow = async (row) => {

    setShowProblemDetail(true);
  }

  const handleCloseDetail = async() =>{
    setShowProblemDetail(false);
  }




 // 处理选择变化
 const handleChange = (event) => {
   setSelectedValue(event.target.value);
   console.log('Selected value:', event.target.value);
 };


const mockData = [
  { id: 1, label: '水题' },
  { id: 2, label: '入门' },
  { id: 3, label: 'CSP-J' },
  { id: 4, label: 'CSP-S' },
  { id: 5, label: 'NOIP' },
  { id: 6, label: 'NOI' },
];

const [searchText,setSearchText] = useState('');

const handleChangeSearchText = (event) => {
  setSearchText(event.target.value);
  
}
const handleAddProblem = (event) => {
  
  
}



const [showProblemList,setShowProblemList] = useState(false);
const [showMyHomeWork,setShowMyHomeWork] = useState(true);

const handleShowProblemList = async() => {
  setIsLoading(true);
  setShowProblemList(true);
  setShowMyHomeWork(false);
  setIsLoading(false);
  
}
const handleShowMyHomework = async () => {
  //setShowProblemList(false);
  //setShowMyHomeWork(true);
  fetchHomeWork();
}

const handleDetailClick = (row) => {
  // 打开详细信息逻辑，例如打开对话框或导航到新页面
  console.log("详细信息:", row);
  setShowProblemDetail(true);
};




    return (
      <div 
        style={{ 
          backgroundColor:get_color_by_name('pop-window-bg'), 
          height: '100%', display: 'flex', 
          flexDirection: 'column',
          border: `1px solid ${get_color_by_name('pop-window-border-color')}`, }}>
      {/* Top toolbar */}
      <div style={{ 
       
          height: '3em', 
          backgroundColor:get_color_by_name('message-item-bg'), 
          padding: '0.5em', 
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
          display: 'flex', // 使子元素水平排列
          alignItems: 'center', // 垂直方向对齐
          gap: '20px' // 控件之间的间隔为 20px
          
        }}>
      <CustomButton sx={{ marginRight: '10px', height: '100%' }} onClick={handleShowMyHomework}>
        {t('刷新作业')}
      </CustomButton>
      {/* 
      <CustomButton sx={{ marginRight: '10px', height: '100%' }} onClick={handleShowProblemList}>
        {t('题库')}
      </CustomButton>
      */}
      <FormControl sx={{ minWidth: 120, height: '100%' }}>
        
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          onChange={handleChange}
          sx={{ height: '32px' }} // 保证 Select 高度与父容器一致
        >
          {mockData.map((item) => (
            <MenuItem key={item.id} value={item.label}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
            variant="outlined"
            value={searchText}
            onChange={handleChangeSearchText}
            sx={{
              width:'200px',
              backgroundColor: get_color_by_name('mui-textfield-bg'),
              // Targeting the input element for styling
              '& .MuiInputBase-input': {
                color: get_color_by_name('headline-color'), // Change text color
              },
            }}
          />
      <CustomButton sx={{ marginRight: '10px', height: '100%' }}>
        {t('跳转')}
      </CustomButton>
      <CustomButton sx={{ marginRight: '10px', height: '100%' }}>
        {t('搜索')}
      </CustomButton>
      {/* 
      <CustomButton sx={{ marginRight: '10px', height: '100%' }} onClick={handleAddProblem}>
        {t('添加题目')}
      </CustomButton>
          */}
    </div>
      
      {/* Bottom content area */}

      {showProblemList && (

      <>
        <div style={{ flexGrow: 1,backgroundColor:get_color_by_name('pop-window-bg'), padding: '10px', display: 'flex' }}>
          {/* Left section */}
          <div style={{ flexBasis: '70%', backgroundColor:get_color_by_name('message-item-bg'), marginRight: '10px'}}>
            <div>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow sx={{backgroundColor:get_color_by_name('pop-window-bg')}}>
                          <TableCell sx={{width:'100px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('problem number')}</TableCell>
                          <TableCell sx={{width:'150px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('problem title')}</TableCell>
                          <TableCell sx={{width:'100px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('code language')}</TableCell>
                          <TableCell sx={{width:'300px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('tags')}</TableCell>
                          <TableCell sx={{width:'150px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('difficulty level')}</TableCell>
                          <TableCell sx={{width:'80px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('difficulty t')}</TableCell>
                          <TableCell sx={{width:'60px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('status')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {tableData.map((row, index) => (
                          <TableRow key={row.id} selected={selectedRowId === row.id}
                            onClick={() => {
                              setSelectedRowId(row.id);
                              setSelectedRowData(row);
                              setProblemDetailType("p");
                            }}
                            hover
                            style={{ cursor: 'pointer', backgroundColor: selectedRowId === row.id ? get_color_by_name('mui-button-bg') : get_color_by_name('pop-window-bg') }}
                          >
                            <TableCell
                              sx={{
                                fontFamily: 'siyuan',
                                color: get_color_by_name('mui-textfield-text-color'),
                                textDecoration: 'underline', // 添加下划线
                                cursor: 'pointer' // 鼠标悬停时显示为可点击
                              }}
                              onClick={() => handleClickRow(row)} // 传递row参数
                              
                            >
                              {row.question_code}
                            </TableCell>
                            <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}} title={row.question_title} >{row.question_title.length > 20 ? row.question_title.substring(0, 20) + "..." : row.question_title}</TableCell>
                            <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.code_language}</TableCell>
                            <TableCell sx={{ fontFamily: 'siyuan', color: get_color_by_name('mui-textfield-text-color') }}>
                              {/* 判断 tag_names 是否为空字符串 */}
                              {row.tag_names ? (
                                row.tag_names.split(',').map((tagName, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: 'inline-block', // 标签并排显示
                                      padding: '4px 8px', // 内边距
                                      margin: '2px', // 外边距
                                      backgroundColor: getRandomColor(), // 使用状态中的背景色
                                      borderRadius: '4px', // 圆角
                                      color: '#fff', // 字体颜色
                                    }}
                                  >
                                    {tagName.trim()} {/* 去掉每个标签两端的空格 */}
                                  </div>
                                ))
                              ) : (
                                <div style={{ color: '#888' }}>No tags available</div> // 当 tag_names 为空时显示的占位文本
                              )}
                            </TableCell>
                            <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.difficulty_level}</TableCell>
                            <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.difficulty_t}</TableCell>
                            <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.difficulty_t}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    
                  </TableContainer>
                  <Pagination
                    sx={{ backgroundColor:get_color_by_name('mui-textfield-bg') , borderRadius: '10px' }}
                    count={totalPagesSession}
                    page={currentPage}
                    onChange={handleChangePage}
                    color="primary"
                  />
            </div>

          </div>
          {/* Right section */}
          <div style={{ flexBasis: '30%', marginLeft:'10px', 
            backgroundColor: get_color_by_name('pop-window-bg'), padding: '1em',
            }}>
          <Box
            sx={{
              flexBasis: '30%',
              backgroundColor: get_color_by_name('pop-window-bg'),
              border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
              padding: '1em',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '10px', // 标签之间的间距
              height:'100%',
              width:'100%',
            }}
          >
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                variant="outlined"
                sx={{
                  cursor: 'pointer',
                  backgroundColor: tagColors[tag], // 使用初始化时生成的颜色
                  color: '#ffffff',
                }}
                onClick={() => console.log(`${tag} clicked`)}
              />
            ))}
          </Box>
          </div>
        </div>
      </>
      )}
      {showMyHomeWork && (
        <>
          <TableContainer component={Paper}>
  <Table>
    <TableHead>
      <TableRow sx={{backgroundColor:get_color_by_name('pop-window-bg')}}>
        <TableCell sx={{width:'240px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('question title')}</TableCell>
        <TableCell sx={{width:'80px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('question code')}</TableCell>
        <TableCell sx={{width:'200px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('tag_names')}</TableCell>
        <TableCell sx={{width:'100px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('create time')}</TableCell>
        <TableCell sx={{width:'60px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('code language')}</TableCell>
        <TableCell sx={{width:'100px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('run time')}</TableCell>
        <TableCell sx={{width:'100px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('run memory')}</TableCell>
        <TableCell sx={{width:'100px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('commit time')}</TableCell>
        <TableCell sx={{width:'150px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('isBuildSuccess')}</TableCell>
        <TableCell sx={{width:'100px', fontFamily:'siyuan',color:get_color_by_name('headline-color')}}>{t('detail')}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {tableDataMyHomework.map((row, index) => (
        <TableRow key={row.id} selected={selectedRowMyHomeworkId === row.id}
          onClick={() => {
            setProblemDetailType('s');
            setSelectedRowMyHomeworkId(row.id);
          }}
          hover
          style={{ cursor: 'pointer', backgroundColor: selectedRowMyHomeworkId === row.id ? get_color_by_name('mui-button-bg') : get_color_by_name('pop-window-bg') }}
        >
          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.question_title}</TableCell>
          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.question_code}</TableCell>
          <TableCell sx={{ fontFamily: 'siyuan', color: get_color_by_name('mui-textfield-text-color') }}>
                              {/* 判断 tag_names 是否为空字符串 */}
                              {row.tag_names ? (
                                row.tag_names.split(',').map((tagName, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: 'inline-block', // 标签并排显示
                                      padding: '4px 8px', // 内边距
                                      margin: '2px', // 外边距
                                      backgroundColor: getRandomColor(), // 使用状态中的背景色
                                      borderRadius: '4px', // 圆角
                                      color: '#fff', // 字体颜色
                                    }}
                                  >
                                    {tagName.trim()} {/* 去掉每个标签两端的空格 */}
                                  </div>
                                ))
                              ) : (
                                <div style={{ color: '#888' }}>No tags</div> // 当 tag_names 为空时显示的占位文本
                              )}
                            </TableCell>

          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.createtime}</TableCell>
          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.code_language}</TableCell>
          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.run_time}</TableCell>
          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.run_memory}</TableCell>
          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>{row.commit_time}</TableCell>
          <TableCell sx={{ fontFamily: 'siyuan', color: 'inherit' }}>
            <Box
              sx={{
                display: 'inline-block',
                padding: '2px 8px',  // 内边距调整矩形的大小
                borderRadius: '12px',  // 圆角设置
                backgroundColor: 
                  row.isBuildSuccess === 0 ? 'green'   // 编译成功
                  : row.isBuildSuccess === 1 ? 'red'    // 编译失败
                  : row.isBuildSuccess === 2 ? 'blue'   // 已提交
                  : 'gray',                     // 未提交
                color: 'white',  // 文字颜色
              }}
            >
              {row.isBuildSuccess === 0 ? '编译成功'
              : row.isBuildSuccess === 1 ? '编译失败'
              : row.isBuildSuccess === 2 ? '已提交'
              : '未提交'}
            </Box>
          </TableCell>

          <TableCell sx={{fontFamily:'siyuan',color:get_color_by_name('mui-textfield-text-color')}}>
            <IconButton onClick={() => handleDetailClick(row)} sx={{color: get_color_by_name('mui-textfield-text-color')}}>
              <InfoIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
                  <Pagination
                    sx={{ backgroundColor:get_color_by_name('mui-textfield-bg') , borderRadius: '10px' }}
                    count={totalPagesSession}
                    page={currentPage}
                    onChange={handleChangePage}
                    color="primary"
                  />
        </>
      )}

      <Modal
          open={showProblemDetail}
          onClose={handleCloseDetail}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{zIndex:5555}}
        >
        <div style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Paper
            style={{
              zIndex: 55545,
              border: `1px solid ${get_color_by_name('pop-window-border-color')}`,
              backgroundColor:get_color_by_name('pop-window-bg'),
              padding: '1em', // Optional: add some padding to the Paper
              width: '95%',  // Optional: adjust the width as needed
              height:'890px'
            }}
          >
            {/* Your content goes here */}
            <ProblemDetail onClose={handleCloseDetail} spid={selectedRowMyHomeworkId} pid={selectedRowId} detailtype={problemDetailType}/>
          </Paper>
        </div>
      </Modal>

      {isLoading && (
        <LoadingData text={t(loadingText)}/>
      )}

    </div>
    )
};

export default MyOiModule;